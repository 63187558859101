import React from "react"
import { Col } from "react-bootstrap"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Moment from "react-moment"
import person from "../../images/person-holder.jpg"

const slugify = require("slugify")

const PostCard = node => {
  return (
    <Col md={4} className={`mb-4`}>
      <LazyLoadComponent>
        <div className="grid-item-inner shadowed h-100">
          <div className="blog-item-featured">
            <a
              aria-label="link"
              href={`/${node.slug.current}/`}
              className="d-inline-block blog-img"
            >
              <GatsbyImage
                image={
                  node.mainImage !== null
                    ? node.mainImage.asset.gatsbyImageData
                    : null
                }
                imgClassName="img-fluid desktop-img-blog"
                alt={node.name}
                style={{ height: "13rem" }}
                layout="constrained"
                placeholder="blurred"
              />
              <img
                src={node.mainImage !== null ? node.mainImage.asset.url : null}
                className="img-fluid mobile-img-blog"
                alt={node.name}
              />
            </a>
          </div>
          <div className="blog-item-holder">
            <ul className="blog-category d-flex flex-wrap list-unstyled">
              {node.categories.map((category, idx) => {
                return (
                  <li>
                    <Link
                      to={`/category/${slugify(category.title.toLowerCase())}/`}
                    >
                      {(idx ? ", " : "") + category.title}
                    </Link>
                  </li>
                )
              })}
            </ul>

            <h3 className="blog-item-title">
              <Link
                className="text-dark"
                style={{ boxShadow: `none` }}
                to={`/${node.slug.current}/`}
                title={node.title}
              >
                {node.title}
              </Link>
            </h3>
            <div className="blog-item-readmore">
              <Link aria-label="link" to={`/${node.slug.current}/`}>
                Read More
              </Link>
            </div>
            <div className="blog-item-info row">
              <div className="col-lg-3 col-md-5 col-3 pr-lg-0 pr-md-0 pr-0 align-self-center">
                <div className="blog-item-author-image">
                  <GatsbyImage
                    placeholder="blurred"
                    className="rounded-circle"
                    imgClassName="rounded-circle"
                    image={
                      node.author !== null
                        ? node.author.image.asset.gatsbyImageData
                        : person
                    }
                    alt={node.author ? node.author.name : "Dev Team"}
                    // width={600}
                    style={{ height: "5.5vh" }}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-md-7 col-9 ml-lg-0 pl-lg-0 pl-md-0 pl-0">
                <div className="blog-item-author-content">
                  {node.author !== null ? (
                    <div className="blog-item-author d-inline-block">
                      <span>by</span>{" "}
                      <Link
                        to={`/author/${slugify(
                          node.author.name.toLowerCase()
                        )}/`}
                        data-wpel-link="internal"
                      >
                        {node.author.name}
                      </Link>
                    </div>
                  ) : null}

                  <div className="blog-item-date">
                    <Moment add={{ days: 1, hours: 9 }} format="MMMM DD, YYYY">
                      {node.publishedAt}
                    </Moment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoadComponent>
    </Col>
  )
}

export default PostCard
