import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"

import "react-lazy-load-image-component/src/effects/blur.css"

import process from "../../images/process-bg.jpg"
import warehouse from "../../images/warehouse-bg.jpg"

export default function LongFormBlogs() {
  return (
    <div className="text-center techblog-section py-5">
      <Container>
        <div className="page-intro">
          <h5 className="text-light-blue font-weight-bold">
            BUSINESS TRANSFORMATION GUIDES
          </h5>
          <h2 className=" font-weight-medium"></h2>
          <p className="my-4"></p>
        </div>
        <div className="justify-content-center row text-left">
          <div className="mb-4 col-md-4">
            <div className="grid-item-inner shadowed h-100">
              <div className="blog-item-featured">
                <a
                  aria-label="link"
                  href="/the-procurement-management-strategic-playbook/"
                  className="d-inline-block"
                >
                  <img
                    src={warehouse}
                    className="img-fluid"
                    // className="img-fluid"
                    alt="procurement"
                    style={{ height: "15rem" }}
                  />
                </a>
              </div>
              <div className="blog-item-holder">
                <ul className="blog-category d-flex flex-wrap list-unstyled"></ul>
                <h3 className="blog-item-title">
                  <a
                    className="text-dark"
                    title="The Procurement Management Strategic Playbook"
                    href="/the-procurement-management-strategic-playbook/"
                  >
                    The Procurement Management Strategic Playbook
                  </a>
                </h3>
                <div className="blog-item-readmore">
                  <Link
                    aria-label="link"
                    href="/the-procurement-management-strategic-playbook/"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 col-md-4">
            <div className="grid-item-inner shadowed h-100">
              <div className="blog-item-featured">
                <a
                  aria-label="link"
                  href="/process-improvement-cut-waste-and-improve-business-efficiency/"
                  className="d-inline-block"
                >
                  <img
                    src={process}
                    className="img-fluid"
                    // className="img-fluid"
                    alt="procurement"
                    style={{ height: "15rem" }}
                  />
                </a>
              </div>
              <div className="blog-item-holder">
                <ul className="blog-category d-flex flex-wrap list-unstyled"></ul>
                <h3 className="blog-item-title">
                  <a
                    className="text-dark"
                    title="Technology Consulting: Cut Waste and Improve Business
                        Efficiency"
                    href="/process-improvement-cut-waste-and-improve-business-efficiency/"
                  >
                    Technology Consulting: Cut Waste and Improve Business
                    Efficiency
                  </a>
                </h3>
                <div className="blog-item-readmore">
                  <Link
                    aria-label="link"
                    href="/process-improvement-cut-waste-and-improve-business-efficiency/"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
