import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./news.css"

import LongForm from "../components/longform/longform"
import PostList from "../components/news-list/post-list"

const NewsListPage = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allSanityPost.edges
  const categories = data.allSanityPost.group

  // console.log("test1", posts)

  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo
        title="Our Blog: Thought Leadership for Digitally Driven Companies"
        description="The Quandary Consulting Group blog shares insights and customers stories designed to help IT leaders drive change in their organizations. Learn more.  "
      />
      <div className="news-list-page">
        <div
          id="pagetitle"
          className="page-intro"
          style={{ paddingTop: "6rem", paddingBottom: "4rem" }}
        >
          <div className="container">
            <div className="page-title-inner text-center">
              <h5 className="text-light-blue font-weight-bold">
                THE QUANDARY CONSULTING GROUP BLOG
              </h5>
              <h1 className="page-title">
                Explore Our Best Strategies and Insights on Business
                Transformation
              </h1>
            </div>
          </div>
        </div>
        <PostList posts={posts} categories={categories} />
        <LongForm />
        <section id="quotes" style={{ padding: "5rem 0" }}>
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-12">
                <h5 className="text-light-blue font-weight-bold">
                  Transform How Your Business Functions
                </h5>
                <h2 className="font-weight-medium">
                  Improve Workflows. Reduce Bottlenecks. Give Your Team More
                  Time and Your Business More Resources. Remain Competitive.
                </h2>
                <div className="text-center">
                  <a
                    className="text-light btn btn-arrow btn-primary mt-3 text-center mx-auto"
                    href="/contact"
                    alt="Reviews"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Speak to a Technology Consultant
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className="py-5 bg-white text-center">
          <Container>
            <h4 className="m-0">
              Custom workflow solutions crafted by a team who knows what you
              need
            </h4>
          </Container>
        </div> */}
      </div>
    </Layout>
  )
}

export default NewsListPage

export const pageQuery = graphql`
  query pageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          _createdAt
          publishedAt
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          mainImage {
            asset {
              url
            }
          }
          author {
            name
            image {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
          slug {
            current
          }
          body
          categories {
            title
          }
        }
      }
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            title
            author {
              name
              image {
                asset {
                  gatsbyImageData(
                    fit: FILL
                    placeholder: BLURRED
                    sizes: ""
                    layout: CONSTRAINED
                  )
                }
              }
            }
            publishedAt
            categories {
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`
